<template>
  <div>
    <div v-if="reports.data && reports.data.length" class="position-table">
      <md-table
        v-model="reports.data"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="['row', { viewed: item.is_viewed }]"
          @click="openReportModal(item.id, item.is_viewed)"
        >
          <md-table-cell md-label="ID" md-sort-by="id" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="ID заказа"
            md-sort-by="order_id"
            class="custom-field"
          >
            <span>{{ item.order_id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Имя пользователя"
            md-sort-by="user_name"
            class="custom-field"
          >
            <span>{{ item.user_name }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="E-mail"
            md-sort-by="email"
            class="custom-field"
          >
            <span>{{ item.email }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Текст проблемы"
            md-sort-by="trouble_text"
            class="custom-field"
          >
            <span>{{ item.trouble_text }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Действие"
            md-sort-by="action_text"
            class="custom-field"
          >
            <span>{{ item.action_text }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="reports.meta.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="reports.meta.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ reports.meta.current_page }}</span>
          /
          <span>{{ reports.meta.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="reports.meta.current_page === reports.meta.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalReport v-if="showReportModal" @close="showReportModal = false" />
  </div>
</template>

<script>
import ModalReport from "@/components/Modals/Report";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalReport,
  },

  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
      showReportModal: false,
    };
  },

  computed: {
    ...mapState({
      reports: (state) => state.reports.reports,
    }),
  },

  methods: {
    ...mapActions(["getCounters"]),
    ...mapActions("reports", ["getReport"]),

    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });
      return value;
    },

    async openReportModal(report_id, isViewed) {
      this.$store.commit("SET_SHOW_LOADER", true);
      await this.getReport(report_id);
      if (!isViewed) {
        this.getCounters();
      }
      this.showReportModal = true;
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-field {
  max-width: 150px;

  &::v-deep {
    .md-table-cell-container {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left !important;
    }
  }
}

.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;

    &.row.viewed {
      opacity: 0.6;
    }

    &.row:not(.viewed) {
      background-color: #c7f9ff;
    }
  }
}
</style>
