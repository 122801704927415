<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <h4>Изменить статус репорта</h4>
        <ValidationObserver ref="sendAnswerForm" :slim="true">
          <div>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус</label>
                <md-select v-model="answer.status" @focus="reset">
                  <md-option
                    v-for="(item, index) in statuses"
                    :key="`status_${index}`"
                    :value="item"
                    placeholder="Статус"
                  >
                    {{ getStatus(item) }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <md-field>
              <label>Комментарий</label>
              <md-textarea v-model.trim="answer.comment" type="text" />
            </md-field>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="sendAnswer">
                Сохранить
              </md-button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      answer: {
        status: null,
        comment: null,
      },
    };
  },

  computed: {
    ...mapState({
      report: (state) => state.reports.report,
      statuses: (state) => state.reports.statuses,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getStatuses();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("reports", ["changeStatus", "getStatuses"]),

    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.close();
      }
    },

    close() {
      this.$emit("close");
    },

    getStatus(status) {
      switch (status) {
        case "new":
          return "Новый";
        case "in_process":
          return "В процессе";
        case "finished":
          return "Завершен";
        default:
          return "";
      }
    },

    async sendAnswer() {
      const isValid = await this.$refs.sendAnswerForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.changeStatus({
        id: this.report.id,
        answer: this.answer,
      });
      if (success) {
        this.close();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
    width: 600px;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.buttons-wrap {
  display: flex;
}
</style>
