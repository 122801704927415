<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <div class="title-wrap">
          <h4 class="md-title">
            Обращение №{{ report.id }}
            <span class="icon" @click="showAnswerModal = true">
              <md-icon> edit </md-icon>
            </span>
          </h4>
        </div>

        <div v-if="images.length" class="images-wrapper">
          <VueUploadMultipleImage
            accept="image/jpeg, image/png, image/jpg"
            :max-image="report.images.length"
            :show-edit="false"
            :show-delete="false"
            :show-add="false"
            :show-primary="false"
            :data-images="images"
          />
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">ID заказа:</span>
                {{ report.order_id }}
              </p>
            </md-field>

            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Проблема:</span>
                {{ report.trouble_text }}
              </p>
            </md-field>

            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Действие:</span>
                {{ report.action_text }}
              </p>
            </md-field>

            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Имя пользователя:</span>
                {{ report.user.name }}
              </p>
            </md-field>

            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">E-mail пользователя:</span>
                {{ report.email }}
              </p>
            </md-field>

            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Текст обращения:</span>
                {{ report.text }}
              </p>
            </md-field>
          </div>
        </div>
      </div>

      <ModalReportAnswer
        v-if="showAnswerModal"
        @close="showAnswerModal = false"
      />
    </div>
  </transition>
</template>

<script>
import ModalReportAnswer from "@/components/Modals/ReportAnswer";

import { mapState } from "vuex";

import VueUploadMultipleImage from "vue-upload-multiple-image";

export default {
  components: {
    ModalReportAnswer,
    VueUploadMultipleImage,
  },

  data() {
    return {
      showAnswerModal: false,
    };
  },

  computed: {
    ...mapState({ report: (state) => state.reports.report }),

    images() {
      return this.report.images?.length
        ? this.report.images.map((item) => {
            return { path: item.image, highlight: 0, default: 0 };
          })
        : [];
    },
  },

  methods: {
    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.$emit("close");
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
    min-width: 500px;
    max-width: 900px;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.title-wrap {
  display: flex;
  justify-content: center;

  .md-title {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
      display: flex;
    }
  }
}

.info {
  width: max-content;
}

.images-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
